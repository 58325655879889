.block-product-list-item {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: calc(25% - 20px);
	margin-left: 20px;
	margin-top: 30px;

	@include media("<=tablet") {
		width: calc(50% - 20px);
		margin-top: 20px;
	}

	&:nth-child(2n) {
		@include media("<=tablet") {
			margin-right: 0;
		}
	}

	&:nth-child(4n) {
		margin-right: 0;
	}

	.product-label {
		z-index: z(product-list-item-label);
	}

	.image-wrapper {
		position: relative;
		height: 0;
		padding-top: 140%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		z-index: z(product-list-item-image);
		max-width: 216px;
		width: 100%;
		max-height: 302px;
		height: 100%;
		opacity: 1;

		@include media("<=tablet") {
			max-width: 100%;
			max-height: 100%;
		}

		&.is-hover-image {
			z-index: z(product-list-item-hover-elements);
			opacity: 0;
		}
	}

	.available-sizes-wrapper {
		position: absolute;
		bottom: 5px;
		left: 5px;
		z-index: z(product-list-item-hover-elements);
		width: calc(100% - 10px);
		padding: 6px 10px 12px;
		opacity: 0;
		background-color: rgba(255, 255, 255, 0.95);
		text-align: center;

		.title {
			margin-bottom: 14px;
			font-size: 12px;
			color: $body-text-color;
			line-height: 17px;
			letter-spacing: $letter-spacing-narrow;
			font-weight: $light;
		}
	}

	.available-sizes {
		line-height: 19px;
	}

	.available-size {
		display: inline-block;
		vertical-align: middle;
		padding: 0 5px;
		font-family: $secondary-font-family;
		font-size: 14px;
		color: $body-text-color;
		line-height: 1.2em;
		letter-spacing: $letter-spacing-medium;
	}

	.product-name {
		margin-top: 15px;
		margin-bottom: 8px;
		color: #000000;
		font-size: 12px;
		font-weight: $regular;
		line-height: 16px;

		@include media("<=tablet") {
			margin-top: 12px;
			margin-bottom: 6px;
		}
	}

	.product-prices {
		.regular-price {
			margin-right: 10px;
		}

		.promo-percent {
			@include media("<=tablet") {
				display: none;
			}
		}
	}
}
