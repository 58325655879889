.banner-rows {
	margin-top: 30px;
	font-size: 0;

	@include media("<=tablet") {
		margin-top: 20px;
	}

	.block-newsletter-subscription {
		margin-bottom: 60px;
		background: #EA0122;
		text-align: center;

		.image {
			display: inline-block;
			width: 100%;
			max-width: 452px;
			vertical-align: middle;

			img {
				width: 100%;
			}

			@include media("<=tablet") {
				max-width: 40%;
			}

			@include media("<=780px") {
				display: block;
				max-width: 100%;
			}
		}

		.subscription {
			display: inline-block;
			width: 100%;
			max-width: calc(100% - 452px);
			vertical-align: middle;
			padding: 0 60px;

			@include media("<=tablet") {
				max-width: 60%;
				padding: 0 20px;
			}

			@include media("<=780px") {
				display: block;
				max-width: 100%;
				padding: 30px 20px 40px 20px;
			}

			.title {
				display: block;
				font-family: "Montserrat", sans-serif;
				font-size: 24px;
				color: #fff;
				line-height: 28px;
				font-weight: 700;
				margin-bottom: 10px;
			}

			.subtitle {
				display: block;
				font-family: "Montserrat", sans-serif;
				font-size: 16px;
				color: #fff;
				line-height: 22px;
				font-weight: 500;
				margin-bottom: 30px;
			}

			form {
				display: inline-block;
				vertical-align: middle;
				width: 100%;
				text-align: left;

				.newsletter-field {

					input[type="text"] {
						border: 1px solid #000000;
					}
				}

				.consents {
					margin-bottom: 0;

					.form-group-checkbox {

						&:last-child {
							margin-bottom: 0;
						}

						.form-element-wrapper {
							vertical-align: middle;

							input[type="radio"], input[type="checkbox"] {
								width: 18px !important;
								height: 18px !important;
								cursor: pointer;
								border: 1px solid #000;
								background: #fff;
							}
						}

						.form-label-wrapper {
							vertical-align: middle;
							top: 2px;
							width: calc(100% - 25px);

							.form-label {
								color: #fff;
							}
						}
					}
				}
			}
		}
	}

	.new-arrivals {
		margin-bottom: 60px;

		@include media("<=tablet") {
			margin-bottom: 40px;
			padding: 0 20px;
		}

		.title {
			margin-bottom: 30px;
			font-size: 24px;
			font-weight: $bold;
			line-height: 28px;
			text-transform: uppercase;

			@include media("<=tablet") {
				text-align: center;
			}
		}

		.new-arrivals-content {

			.flickity-button {
				@include box-shadow(none);

				position: absolute;
				background: transparent;
				border: none;
				color: transparent;

				@include media("<=tablet") {
					display: none;
				}

				&:active {
					opacity: 1;
				}

				&.flickity-prev-next-button {
					top: 145px;
					width: 32px;
					height: 32px;
					padding: 0;
					border-radius: 0;

					&.previous {
						left: -15px;
						background: url('/images/icons/icon-arrow-left.svg') no-repeat center center;
						transform: translateX(-100%);
					}

					&.next {
						right: -15px;
						background: url('/images/icons/icon-arrow-right.svg') no-repeat center center;
						transform: translateX(100%);
					}

					.flickity-button-icon {
						display: none;
					}
				}
			}

			.flickity-page-dots {
				bottom: 0;

				.dot {
					width: 56px;
					height: 3px;
					margin: 0 5px;
					background: #A4A4A4;
					border-radius: 0;
					opacity: 1;

					&.is-selected {
						background: #000000;
					}
				}
			}
		}
	}

	.new-arrivals .block-product-list-item {
		width: calc(20% - 16px);
		margin-right: 20px;

		@include media("<=tablet") {
			width: calc(50% - 5px);
			margin-right: 10px;
		}

		&:nth-child(5n) {
			margin-right: 0;

			@include media("<=tablet") {
				margin-right: 10px;
			}
		}

		&:nth-child(2n) {
			@include media("<=tablet") {
				margin-right: 0;
			}
		}

		//&:nth-child(4) {
		//	margin-top: 0;
		//}

		.product-link {

			.product-label{
				position: absolute;
				top: 10px;
				left: 10px;
				display: inline-block;
				padding: 6px 9px;
				color: #ffffff;
				font-size: 14px;
				font-weight: $bold;
				line-height: 16px;
				text-align: center;

				&.promo {
					right: 10px;
					left: auto;
					min-width: 56px;
					background: $promo-color;

					@include media("<=tablet") {
						min-width: 39px;
					}
				}
			}
		}
	}
}

.banner-row {
	&:not(:last-child) {
		margin-bottom: 60px;

		@include media("<=tablet") {
			margin-bottom: 40px;
		}
	}

	@include media("<=tablet") {
		padding: 0 20px;
	}

	// Full width banner row
	&[data-type="full-width"] {
		position: relative;
		overflow: hidden;

		img {
			@extend %align-center;
			display: block;
			width: 100%;
		}

		.flex-direction-nav {
			@extend %align-absolute-vertical;
			z-index: 2;
			width: 100%;

			@include media("<=tablet") {
				display: none;
			}

			.flex-prev {
				position: absolute;
				top: 0;
				left: 0;
				width: 56px;
				height: 56px;

				&:before {
					@extend %align-absolute-center;

					content: "\f104";
					font-family: FontAwesome;
					font-size: 56px;
					color: #fff;
				}
			}

			.flex-next {
				position: absolute;
				top: 0;
				right: 0;
				width: 56px;
				height: 56px;

				&:before {
					@extend %align-absolute-center;

					content: "\f105";
					font-family: FontAwesome;
					font-size: 56px;
					color: #fff;
				}
			}
		}

		.flex-control-nav {
			@extend %align-absolute-horizontal;

			z-index: 2;
			bottom: 32px;

			@include media("<=tablet") {
				display: none;
			}

			li {
				@include border-radius(50%);

				position: relative;
				display: inline-block;
				vertical-align: top;
				width: 12px;
				height: 12px;
				font-size: 0;
				background-color: #fff;
				border: 1px solid #000;

				&:not(:last-child) {
					margin-right: 15px;
				}
			}

			a {
				display: block;
				width: 100%;
				height: 100%;

				&.flex-active:before {
					@include border-radius(50%);
					@extend %align-absolute-center;

					content: "";
					width: 6px;
					height: 6px;
					background-color: #000;
				}
			}
		}
	}

	&[data-type="small-banner-big-banner"],
	&[data-type="big-banner-small-banner"] {
		.banner {
			&:not(:last-child) {
				margin-right: 20px;

				@include media("<=tablet") {
					margin-right: 0;
					margin-bottom: 40px;
				}
			}
		}
	}

	.banner {

		// Half width banner
		&[data-type="half-width"] {
			display: inline-block;
			vertical-align: top;
			width: calc(50% - 10px);

			@include media("<=tablet") {
				width: 100%;
			}

			&:not(:last-child) {
				margin-right: 20px;

				@include media("<=tablet") {
					margin-right: 0;
					margin-bottom: 40px;
				}
			}

			img {
				@extend %align-center;

				display: block;
				max-width: 100%;
				max-height: 100%;
			}

			.info-box {
				padding: 0 10px;
				text-align: center;
				color: $body-text-color;

				@include media("<=tablet") {
					padding: 0;
				}
			}

			.title {
				margin-top: 30px;
				font-size: 24px;
				font-weight: $bold;
				line-height: 28px;
				text-transform: uppercase;

				@include media("<=tablet") {
					margin-top: 20px;
				}
			}

			.text {
				@extend %align-center;

				max-width: 70%;
				margin-top: 15px;
				font-size: 16px;
				font-weight: $medium;
				line-height: 22px;

				@include media("<=tablet") {
					max-width: 100%;
				}
			}

			button {
				padding: 13px 40px;
				margin-top: 30px;
				font-weight: $semi-bold;

				@include media("<=tablet") {
					margin-top: 20px;
				}
			}
		}

		// Three equal-width banners
		&[data-type="three-equal-width"] {

			display: inline-block;
			vertical-align: top;
			width: calc(33.33% - 14px);

			@include media("<=tablet") {
				width: 100%;
			}

			&:not(:last-child) {
				margin-right: 20px;

				@include media("<=tablet") {
					margin-right: 0;
					margin-bottom: 30px;
				}
			}

			img {
				@extend %align-center;

				display: block;
				max-width: 100%;
				max-height: 100%;
			}

			.info-box {
				padding: 0 10px;
				text-align: center;
				color: $body-text-color;

				@include media("<=tablet") {
					padding: 0;
				}
			}

			.title {
				margin-top: 30px;
				font-size: 24px;
				font-weight: $bold;
				line-height: 28px;
				text-transform: uppercase;

				@include media("<=tablet") {
					margin-top: 20px;
				}
			}

			.text {
				@extend %align-center;

				max-width: 70%;
				margin-top: 15px;
				font-size: 16px;
				font-weight: $medium;
				line-height: 22px;

				@include media("<=tablet") {
					max-width: 100%;
				}
			}

			button {
				padding: 13px 40px;
				margin-top: 30px;
				font-weight: $semi-bold;

				@include media("<=tablet") {
					margin-top: 20px;
				}
			}
		}

		// Big banner
		&[data-type="big"] {
			display: inline-block;
			vertical-align: top;
			width: calc(67% - 10px);

			@include media("<=tablet") {
				width: 100%;
			}

			img {
				@extend %align-center;

				display: block;
				max-width: 100%;
				max-height: 100%;
			}

			.info-box {
				padding: 0 10px;
				text-align: center;
				color: $body-text-color;

				@include media("<=tablet") {
					padding: 0;
				}
			}

			.title {
				margin-top: 30px;
				font-size: 24px;
				font-weight: $bold;
				line-height: 28px;
				text-transform: uppercase;

				@include media("<=tablet") {
					margin-top: 20px;
				}
			}

			.text {
				@extend %align-center;

				max-width: 70%;
				margin-top: 15px;
				font-size: 16px;
				font-weight: $medium;
				line-height: 22px;

				@include media("<=tablet") {
					max-width: 100%;
				}
			}

			button {
				padding: 13px 40px;
				margin-top: 30px;
				font-weight: $semi-bold;

				@include media("<=tablet") {
					margin-top: 20px;
				}
			}
		}

		// Small banner
		&[data-type="small"] {
			display: inline-block;
			vertical-align: top;
			width: calc(33% - 10px);

			@include media("<=tablet") {
				width: 100%;
			}

			img {
				@extend %align-center;

				display: block;
				max-width: 100%;
				max-height: 100%;
			}

			.info-box {
				padding: 0 10px;
				text-align: center;
				color: $body-text-color;

				@include media("<=tablet") {
					padding: 0;
				}
			}

			.title {
				margin-top: 30px;
				font-size: 24px;
				font-weight: $bold;
				line-height: 28px;
				text-transform: uppercase;

				@include media("<=tablet") {
					margin-top: 20px;
				}
			}

			.text {
				@extend %align-center;

				max-width: 70%;
				margin-top: 15px;
				font-size: 16px;
				font-weight: $medium;
				line-height: 22px;

				@include media("<=tablet") {
					max-width: 100%;
				}
			}

			button {
				padding: 13px 40px;
				margin-top: 30px;
				font-weight: $semi-bold;

				@include media("<=tablet") {
					margin-top: 20px;
				}
			}
		}
	}
}
