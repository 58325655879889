@import "../config";
@import "../components/banner-rows";
@import "../components/product-list-item";

.homepage {
	@extend %fluid-constrained;
	@extend %align-center;

	padding: 0 20px;

	@include media("<=tablet") {
		padding: 0;
	}
}
